.chapterTwoBlock {
    position: relative;
    /*background-color: #C0A3CB;*/
    z-index: 5;
    display: block;
}

.chapterTwoNodeContainer {
    position: relative;
    z-index: 5;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
}

.chapterTwoHeader {
    background-color: #824798;
    height: 20px;
    width: 190px;
    color: white;
    padding: 2px;
    cursor: pointer;
}

.chapterTwoTitleLabel {
    padding: 0 22px;
}

.chapterTwoDeleteIcon {
    height: 20px;
    float: right;
    cursor: pointer;
}
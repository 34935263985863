.BranchSelectionView {
    position: fixed;
    background-color: #C0A3CB;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.BranchSelectionViewBody {
    display: table;
    position: relative;
    width: calc(100% - 128px);
    height: 100%;
    vertical-align: middle;
    padding-left: 64px;
    padding-right: 64px;
}

.BranchSelectionViewLinkContainer {
    display: table-cell;
    vertical-align: middle;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: auto;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.BranchSelectionViewButton {
    width: 160px;
}

.activityIndicator {
    border: 16px solid #550673DC;
    border-top: 16px solid #FFFFFFDC;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 3s linear infinite;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
}

.activityIndicatorHidden {
    display: none;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
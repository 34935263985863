.GameTester {
  text-align: center;
  height: 100%;
  background-color: #C0A3CB;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #550673;
  height: 80px;
  padding: 20px;
  color: white;
}

.Console {
  height: calc(100% - 93px);
  position: relative;
  overflow-y: scroll;
  margin-bottom: 50px;
  padding-bottom: 16px;
}

.ConsoleText {
  white-space: pre-wrap;
  margin: 20px;
  overflow-y: scroll;
}

.SectionIdInput {
    height: 18px;
    outline: none;
    padding-left: 8px;
}

.SectionIdInput:focus {
    border-color: #550673;
    border-style: solid;
    border-width: 1px;
}

.Footer {
  background-color: #a68ab7;
  width: 100%;
  position: fixed;
  padding-bottom: 10px;
  padding-top: 6px;
  bottom: 0;
  left: 0;
}

.activityIndicator {
    border: 16px solid #550673DC;
    border-top: 16px solid #FFFFFFDC;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 3s linear infinite;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
}

.activityIndicatorHidden {
    display: none;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.deleteDialogFrame {
    position: absolute;
    height: 86px;
    width: 236px;
    background-color: white;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
    border-color: rgba(195, 0, 22, 1);
    border-style: solid;
    border-width: 2px;
    padding: 16px 32px;
}

.deleteDialogBackgroundView {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
}

.deleteButton {
    margin-top: 16px;
    background-color: rgba(195, 0, 22, 1);
    width: 104px;
}

.cancelButton {
    margin-top: 16px;
    background-color: rgba(170, 0, 0, 1);
    width: 104px;
}
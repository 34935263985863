.storyboard {
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 0;
    justify-content: space-between;
}

.storyboardContainer {
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(100% - 26px);
    background-color: #C0A3CB;
}

.storyboardContent {
    position: absolute;
    background-color: orange;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.activityIndicator {
    border: 16px solid #550673DC;
    border-top: 16px solid #FFFFFFDC;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 3s linear infinite;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 10;
}

.activityIndicatorHidden {
    display: none;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
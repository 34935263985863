.node {
    margin: 10px;
    background-color: white;
    border-color: #550673;
    border-style: solid;
    border-width: 2px;
}

.nodeTitle {
    background-color: #550673;
    padding: 2px;
    color: white;
    cursor: pointer;
    padding-left: 20px;
    height: 21px;
}

.nodeDescription {
    padding: 8px;
    height: 67px;
    overflow: hidden;
    overflow-y: auto;
}

.nodeDeleteIcon {
    height: 20px;
    float: right;
    margin-top: -2px;
    margin-right: -2px;
    z-index: 10;
}
.DevPortal {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #C0A3CB;
}

.DevPortalUserView {
    background-color: #55067330;
    border-radius: 6px;
    position: fixed;
    width: 150px;
    height: 24px;
    right: 10px;
    top: 36px;
    z-index: 100;
    color: white;
}

.DevPortalUserViewLabel {
    color: white;
    font-size: 15px;
    height: 100%;
    line-height: 24px;
    text-align: center;
    cursor: default;
}

.LogOutDeleteIcon {
    position: relative;
    height: 20px;
    float: right;
    margin-top: 2px;
    margin-right: 2px;
    cursor: pointer;
}

.DevPortalHidden {
    display: none;
}
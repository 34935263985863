.container {
    position: relative;
    height: 26px;
    background-color: #550673;
    display: flex;
    left: 0;
}

.spacer {
    height: 26px;
    width: 2px;
    background-color: white;
    min-width: 2px;
}

.tab {
    color: white;
    height: 26px;
    line-height: 26px;
    cursor: pointer;
    padding: 0 56px;
    text-align: center;
    min-width: 86px;
}

.rightSpacer {
    height: 26px;
    width: 2px;
    background-color: white;
    min-width: 2px;
}

.rightTab {
    color: white;
    height: 26px;
    line-height: 26px;
    cursor: pointer;
    padding: 0 56px;
    text-align: center;
    float: right;
    min-width: 86px;
}

.rightTabContainer {
    position: relative;
    justify-items: right;
    float: right;
    display: flex;
    height: 26px;
    width: 100%;
    right: 0;
}

@media only screen and (max-width: 800px) {
    .container {
        justify-content: space-between;
        align-content: stretch;
    }
    .rightTabContainer {
        display: none;
    }
    .rightTab {
        display: none;
        padding: 0 0;
    }
    .rightSpacer {
        display: none;
    }
    .tab {
        padding: 0 0;
        width: 100%;
    }
}
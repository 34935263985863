.App {
  position: absolute;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
  font-size: 15px;
}

.headerMenuSpacer {
  height: 2px;
}

.Content {
  position: absolute;
  top: 198px;
  min-height: calc(100% - 198px);
  bottom: auto;
  left: 0;
  right: 0;
  padding-left: 16px;
  padding-right: 16px;
  background-image: url("./resources/axadalLogoBackgroundTile.svg");
  background-size: 460% 460%;
  background-repeat: no-repeat;
  background-position: 22% 44%;
  background-attachment: fixed;
}

.AppLogo {
  margin: 10px;
  /*animation: AppLogoSpin infinite 20s linear;*/
  height: 110px;
  float: left;
  cursor: pointer;
}

.AppHeader {
  background-color: #550673;
  height: 130px;
  padding: 20px;
  color: white;
}

.AppTitle {
  position: relative;
  margin-left: 10px;
  margin-top: 66px;
  text-align: left;
  font-weight: lighter;
  color: #550673;
  font-size: 56px;
  -webkit-text-stroke-width: 1.2px;
  -webkit-text-stroke-color: white;
  cursor: pointer;
}

.betaGameLink {
  position: absolute;
  color: white;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 670px) {
  .AppTitle {
    visibility: hidden;
  }
  .AppLogo {
    float: none;
  }
  .Content {
      background-position: 4% 42%;
  }
}


@keyframes AppLogoSpin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

button, select {
  color: white;
  background-color: #550673;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
  outline:none;
  border-radius: 0;
}

a:active {
  color: #a68ab7;
}
.DevPortalLandingPage {
    position: fixed;
    background-color: #C0A3CB;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.DevPortalLandingPageBody {
    display: table;
    position: relative;
    width: calc(100% - 128px);
    height: 100%;
    vertical-align: middle;
    padding-left: 64px;
    padding-right: 64px;
}

.DevPortalLandingPageButtonContainer {
    display: table-cell;
    vertical-align: middle;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: auto;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.DevPortalLandingPageButton {
    width: 160px;
}
.nodeTwoOuterContainer {
    margin: 8px 0 8px 0;
}

.nodeTwo {
    margin: 0 10px 0 10px;
    background-color: white;
    border-color: #550673;
    border-style: solid;
    border-width: 2px;
    width: 288px;
}

.nodeTwoTitleBlock {
    background-color: #550673;
    padding: 2px;
    cursor: pointer;
}

.nodeTwoTitle {
    background-color: #550673;
    padding: 2px;
    color: white;
    cursor: pointer;
    padding-right: 36px;
    padding-left: 36px;
    padding-bottom: 6px;
}

.nodeTwoDescription {
    padding: 8px;
    overflow: hidden;
    overflow-y: auto;
}

.nodeTwoDeleteIcon {
    position: relative;
    height: 20px;
    float: right;
    margin-top: -2px;
    margin-right: -2px;
    z-index: 10;
}

.nodeTwoArrowIcon {
    height: 40px;
    margin: 0 10px 0 10px;
}

.nodeTwoArrow {
    color: #550673;
}

.nodeTwoArrowContainer {
    display: flex;
    justify-content: center;
}

.nodeTwoHidden {
    display: none;
}

.nodeTwoMoveArrowsContainer {
    height: 20px;
    width: 32px;
    float: left;
    display: flex;
    justify-content: center;
}

.nodeTwoMoveLeftArrowIcon {
    height: 18px;
}

.nodeTwoMoveLeftArrowIconDisabled {
    height: 18px;
    opacity: 0.5;
}

.nodeTwoMoveRightArrowIcon {
    height: 18px;
}

.nodeTwoMoveRightArrowIconDisabled {
    height: 18px;
    opacity: 0.5;
}
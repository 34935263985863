
.arrowContainer {
    background-color: blueviolet;
    width: 120px;
    height: 100px;
}

.arrowHead {
    /*position: absolute;*/
    top: 0;
    width: 10px;
    margin-top: 0;
    vertical-align: top;
}

.arrowTail {
    width: calc(100% - 10px);
    height: 100%;
}

.connectionCanvas {
    position: relative;
    height: 1000px;
    width: 1000px;
}

.connectionCanvasContainer {
    position: absolute;
    height: calc(100% - 16px);
    width: 100%;
}
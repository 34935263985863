.chapterEditViewFrame {
    position: absolute;
    height: 76px;
    width: 268px;
    background-color: white;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
    border-color: #550673;
    border-style: solid;
    border-width: 2px;
    padding: 16px;
}

.chapterEditBackgroundView {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
}

.indexSelectLabel {
    float: left;
    height: 24px;
    line-height: 24px;
    margin-top: 4px;
    margin-left: 4px;
}

.indexSelect {
    height: 24px;
    width: 120px;
    float: right;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background: #550673 url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#FFFFFF' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
}

.chapterInsertButton {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: 258px;
}

.chapterEditCancelIcon {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
}
.chapterBlock {
    position: relative;
    /*background-color: #C0A3CB;*/
    width: 280px;
    display: block;
    z-index: 5;
}

.chapterHeader {
    background-color: #824798;
    height: 20px;
    color: white;
    padding: 2px;
    cursor: pointer;
}

.chapterTitleLabel {
    padding: 0 22px;
}

.chapterDeleteIcon {
    height: 20px;
    float: right;
    cursor: pointer;
}
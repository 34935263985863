.section {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-around;
}

.group {
    margin-bottom: 8px;
    margin-top: 60px;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-around;
}

.avatar {
    height: 130px;
    width: 130px;
}

.groupSpacer {
    width: 100px;
}


@media only screen and (max-width: 670px) {
    .section {
        flex-direction: column;
    }
    .group {
        display: block;
        margin-top: 40px;
    }
    .avatar {
        margin-right: 10px;
    }
}
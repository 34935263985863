.nodeEditViewFrame {
    position: absolute;
    max-height: 440px;
    max-width: 560px;
    width: calc(100% - 64px);
    background-color: white;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
    border-color: #550673;
    border-style: solid;
    border-width: 2px;
    padding: 16px;
}

.nodeEditBackgroundView {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
}

.connectionsTitleLabel {
    margin-bottom: 2px;
}

.connectionsSelectContainer {
    position: relative;
    border-radius: 0;
    margin-left: 5px;
    width: calc(100% - 10px);
}

.nodeTitleLabel {
    /*width: 100px;*/
    text-align: left;
}

.nodeTitleContainer {
    float: left;
    height: 24px;
    margin-top: 20px;
    margin-left: 5px;
    width: calc(100% - 10px);
}

.titleInput {
    float: right;
    width: calc(100% - 100px);
    height: 24px;
    resize: none;
    outline: none;
    margin-top: -22px;
    line-height: 24px;
    font-size: 15px;
    border-radius: 0;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
}

.titleInput:focus
{
    border-color: #550673;
    border-style: solid;
    border-width: 1px;
    margin-left: 16px;
}

.chapterSelectLabel {
    float: left;
    height: 24px;
    line-height: 24px;
    margin-top: 16px;
    margin-left: 5px;
}

.chapterSelect {
    height: 24px;
    width: 200px;
    min-width: 120px;
    float: right;
    margin-top: 16px;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background: #550673 url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#FFFFFF' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
}

.descriptionTitleLabel {
    position: relative;
    margin-top: 96px;
    margin-bottom: 4px;
}

.descriptionTextArea {
    resize: none;
    width: calc(100% - 16px);
    height: 164px;
    outline: none;
    font-size: 15px;
    border-radius: 0;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
}

.descriptionTextArea:focus {
    border-color: #550673;
    border-style: solid;
    border-width: 1px;
}

.nodeInsertButton {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;
    width: calc(100% - 42px);
}

.nodeEditCancelIcon {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
}

@media only screen and (max-width: 416px) {
    .nodeEditViewFrame {
        width: 300px;
    }
    .titleInput {
        width: 210px;
    }
    .chapterSelect {
        width: 120px;
    }
}